import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import storage from '../../utils/storage';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const pulse = keyframes`
0% {transform: scale(1);}
50% {transform: scale(1.3);}
100% {transform: scale(1);}
`;

const TyContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');
  font-family: 'News Cycle', sans-serif;
  width: 100%;
`;

const GreenBlock = styled.div`
  width: 100%;
  height: 50px;
  background: #6cc14c;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-align: center;

  p {
    font-size: 20px;
  }

  h1 {
    margin-bottom: 10px;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const LowerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h2 {
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 50px auto;

  p {
    width: calc(100% - 20px);
    max-width: 450px;
    font-weight: 600;
    text-align: center;
  }

  button {
    width: calc(100% - 20px);
    max-width: 327px;
    border: none;
    height: 59px;
    border-radius: 40px;
    background: #6cc14c;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    padding: 0 0 5px;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
`;

const GreyLine = styled.div`
  width: 100%;
  border-bottom: 2px solid #a0a0a0;
`;

const EhsrbaThankyou = ({ pageContext: { name = 'ehsrba-thankyou' } }) => {
  const [compressionKey, setCompressionKey] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      const formData = storage('session', 'get', 'jtkNetFd');

      if (formData) {
        const parsedData = JSON.parse(formData);
        const pageCode = {
          PageCode: 'HSRBA',
        };

        const compressionData = { ...parsedData, ...pageCode };
        const compressedValue = await window?.jtknet?.networkDataCompression(
          compressionData
        );
        setData(parsedData);
        setCompressionKey(compressedValue);
      }
    })();
  }, []);

  useEffect(() => {
    if (compressionKey) {
      const cip = new Image();
      cip.src = `https://suited45trk.com/i.ashx?E=5oLLaH72sOxSC1SwbFMgcjHyoULgxPWH&s1=${compressionKey}`;
      cip.setAttribute('width', '1');
      cip.setAttribute('height', '1');
      cip.setAttribute('border', '0');
      document.body.appendChild(cip);
    }
  }, [compressionKey]);

  const handleClick = () => {
    const Address = data?.Address?.replaceAll(' ', '+') || '';

    const prePoppedLink = `https://trkme8.com/?r3x=5oLLaH72sOxSC1SwbFMgcjHyoULgxPWH&s1=${data?.CkmAffiliateId ||
      ''}&s2=${compressionKey || ''}&zip=${data?.ZipCode ||
      ''}&fname=${data?.FirstName || ''}&lname=${data?.LastName ||
      ''}&email=${data?.Email ||
      ''}&street=${Address}&SRCID=${data?.CkmAffiliateId ||
      ''}&city=${data?.City || ''}&state=${data?.State ||
      ''}&phone=${data?.PhoneNumber || ''}&s4=${data?.CkmAffiliateId || ''}`;

    navigate(prePoppedLink);
  };

  const determineSubmitText = {
    'ehsrba-thankyou': 'Visit Renewal by Andersen',
    'ehsrbav2-thankyou': 'View My Savings!',
    'ehsrbav3-thankyou': 'View My Quote!',
  };

  return (
    <TyContainer>
      <GreenBlock />
      <HeadlineContainer>
        <h1>Congratulations!</h1>
        <p>You've been matched with our featured partner:</p>
      </HeadlineContainer>
      <LogoContainer>
        <img
          src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/RBA_Logo.png"
          alt="Renewal by Andersen"
        />
      </LogoContainer>

      <LowerContainer>
        <h2>What happens next?</h2>
        <p>Visit Renewal by Andersen to schedule</p>
        <p>your FREE no-obligation consultation.</p>
      </LowerContainer>
      <ButtonContainer>
        <p>
          (Renewal by Andersen is not involved with any government grants or
          free windows programs. All projects come at a cost to the consumer)
        </p>
        <button type="button" onClick={handleClick} className={name}>
          {determineSubmitText[name] || 'Visit Renewal by Andersen'}
        </button>
      </ButtonContainer>
      <GreyLine />
    </TyContainer>
  );
};

export default withThankyouWrapper(EhsrbaThankyou);
